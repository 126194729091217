<template>
    <div id="mian">
        <div class="header">
            <span @click="navClick('批量查询')" :class="crumbs=='批量查询'?'active':'left'">批量查询</span>
            <span @click="navClick('订单查询')" :class="crumbs=='订单查询'?'active':'left'">订单查询</span> 
        </div>
        <!-- 批量订单查询 -->
        <div v-if="crumbs=='批量查询'">
            <!-- 上面表单框 -->
            <div class="con_from">
                <p>
                    <i>商户名称</i>
                    <el-input v-model="input" style="width:19.5%;height:36px" suffix-icon="el-icon-search"	 placeholder="分账商户名称/编号"></el-input>
                    <i class="jianju">时间类型</i>
                    <el-select v-model="value" placeholder="创建时间" style="width:11%;height:36px;padding-right:42px">
                        <el-option 
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-date-picker 
                    style="width:19.5%;height:36px"
                    v-model="value1"
                    type="datetime"
                    placeholder="选择时间">
                    </el-date-picker>
                    -
                    <el-date-picker
                    style="width:19.5%;height:36px"
                    v-model="value2"
                    type="datetime"
                    placeholder="选择时间">
                    </el-date-picker>
                </p>

                <p>
                    <i>所属商户</i>
                    <el-select v-model="value" placeholder="全部" style="width:19.5%;height:36px">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <i class="jianju">产品类型</i>
                    <el-select v-model="value" placeholder="全部" style="width:19.5%;height:36px;padding-right:100px">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <i >分账状态</i>
                    <el-select  v-model="value" placeholder="全部" style="width:19.5%;height:36px;">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </p>    
                <p>
                    <i>分账类型</i>
                    <el-select  v-model="value" placeholder="全部" style="width:19.5%;height:36px;">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    <el-button type="primary" class="searchBt">导出</el-button>
                    <el-button type="primary" class="searchBt" style="margin-right: 20px;">查询</el-button>              
                </p>
            </div>
            <!-- 中间交易账目 -->
            <div class="jiaoyi">
                <div class="jiaoyi_box" >
                    <p><i>分账金额</i></p>
                    <p><b>14235</b></p>
                </div>
                <div class="jiaoyi_box">
                    <p><i>手续费</i></p>
                    <p><b>14235</b></p>
                </div>
                <div class="jiaoyi_box" >
                    <p><i>笔数</i></p>
                    <p><b>14235</b></p>
                </div>
                <div class="jiaoyi_box">
                    <p><i>商户入账金额</i></p>
                    <p><b>14235</b></p>
                </div>
            </div>
            <div class="tab1">
                <table class="tab">
                    <tr class="tab_title" >
                        <td style="width:60px;padding-left:40px;"><p>创建时间</p> <p>创建时间</p></td>
                        <td style="width:168px"><p>分账商户名称</p> <p>所属商户</p></td>
                        <td style="width:60px"><p>商户订单号</p> <p>交易流水号</p></td>
                        <td style="width:70px"><p>分账订单号</p> <p>分账流水号</p></td>
                        <td><p>产品类型</p></td>
                        <td style="width:57px"><p>分账状态</p><p>分账类型</p></td>
                        <td style="width:93px"><p>交易金额 (元) </p></td>
                        <td style="width:56px"><p>是否担保</p></td>
                        <td><p>操作</p></td>
                    </tr>
                    <tr>
                        <td style="width:138px;padding-left:15px;"><p>2019-01-12 15:51:15</p> <p>2019-01-12 15:51:59</p></td>
                        <td style="margin-left:15px;"><p>深圳有数互动科技有限公司</p></td>
                        <td><p>12345678901234567</p> <p>0000678901234567</p></td>
                        <td><p>-</p> <p>-</p></td>
                        <td style="width:85px"><p>微信扫码支付</p></td>
                        <td><p>支付成功</p></td>
                        <td><p>55.1</p></td>
                        <td><p>否</p></td>
                        <td style="width:66px"><p><i class="lianjie" ><a class="lianjie" href="/details_xq">详情</a></i></p></td>
                    </tr>
                </table>
            </div>  
        </div>
        <!-- 按订单号查询 -->
        <div  v-if="crumbs=='订单查询'">
            <div class="con_from">
                <p>
                <i>商户订单号</i>
                <el-input v-model="input" style="width:19.5%;height:36px" suffix-icon="el-icon-search"	 placeholder="搜索单号"></el-input>
                <el-button type="primary" style="float:right; background:#48B8B6;width:7.5%;height:40px;">查询</el-button>              
                </p>
            </div>
            <div class="tab1">
                <table class="tab">
                    <tr class="tab_title" >
                        <td style="width:60px;padding-left:40px;"><p>创建时间</p> <p>创建时间</p></td>
                        <td style="width:168px"><p>分账商户名称</p> <p>所属商户</p></td>
                        <td style="width:80px"><p>商户分账单号</p> <p>平台分账单号</p></td>
                        <td><p>产品类型</p></td>
                        <td style="width:57px"><p>分账状态</p><p>分账类型</p></td>
                        <td style="width:93px"><p>分账金额 (元) </p></td>
                        <td style="width:70px"><p>是否担保</p></td>
                        <td><p>操作</p></td>
                    </tr>
                    <tr>
                        <td style="width:138px;padding-left:15px;"><p>2019-01-12 15:51:15</p> <p>2019-01-12 15:51:59</p></td>
                        <td style="margin-left:15px;"><p>深圳有数互动科技有限公司</p></td>
                        <td><p>-</p> <p>-</p></td>
                        <td style="width:85px"><p>微信扫码支付</p></td>
                        <td><p>支付成功</p></td>
                        <td><p>55.1</p></td>
                        <td><p>否</p></td>
                        <td style="width:66px"><p><i class="lianjie" ><a class="lianjie" href="/details_xq">详情</a></i></p></td>
                    </tr>
                   
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            value1:"",
            value2:"",
            crumbs:"批量查询",

        }
    },
    methods:{
        //菜单切换
		navClick(data){
			this.crumbs=data
		},
    }
}
</script>
<style scoped>
/* .con_from1{
    padding-top:20px; 
     width: 100%;
    height: 80px;
    position: relative;
    overflow: hidden;
} */

.active{
    color:rgba(72,184,182,1);
    border-bottom:3px solid #48B8B6; 
}
.lianjie{
    color: #008AFF;
}
.tab tr td p{
    width: 100%;
    text-align: center;
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.tab_title{
    background:rgba(244,245,246,1);
    border-top:1px solid rgba(220,224,230,1); 
}
.tab1 .tab tr{
    padding-left:20px; 
    height: 60px;
    border-bottom:1px solid rgba(220,224,230,1); 
}
.tab{
    border-collapse:collapse; 
    width: 100%;
    background: #fff;
    border-bottom:1px solid rgba(220,224,230,1); 
}
.tab1{
    width: 100%;
    margin-top:20px; 
}
.jiaoyi_box p b{
    font-size:24px;
    font-family:PingFang SC;
    font-weight:600;
    color:rgba(72,184,182,1);
}
.jiaoyi_box p i{
    font-size:12px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(153,153,153,1);
}
.jiaoyi_box p{
    padding-top:13px; 
    width: 100%;
    text-align: center;
}
.jiaoyi{
    width: 100%;
    height: 90px;
    position: relative;
    margin-top:20px;
    display: flex;
    justify-content:space-between 
}
.jiaoyi_box{
    float: left;
    width: 24%;
    height: 90px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.05);
    border-radius:5px;
}
.jianju{
    padding-left:125px; 
}
.con_from p i{
    padding-right:12px; 
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(0,0,0,1);
}
.con_from{
    width: 100%;
    position: relative;
    overflow: hidden;

}
.con_from p{
    padding-top:20px;
    width: 100%;
    height: 40px;
    overflow: hidden; 
}
.header span{
    display: inline-block;
    height: 100%;
    width: 120px;
    text-align: center;
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
}
.header{
    line-height: 40px;
    width: 100%;
    height: 40px;
    border-bottom:1px solid  rgba(220,224,230,1);
    position: relative;
    top: 0;
}
#mian{
    padding-left:20px;
    padding-right:21px;  
    width: 96.5%;
    height: 100%;
    background:rgba(255,255,255,1);
    overflow-y:auto;
    overflow-x:hidden; 
}
.searchBt{
  float:right;
  background:#48B8B6;
  width:90px;
  height:36px;
  padding: 0;
  color: #FFFFFF;
}
</style>